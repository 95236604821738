import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";


export const frontmatter = {
  title: "Día 59",
  week: "Semana 9",
  day: "11",
  month: "may",
  monthNumber: "05",
  date: "2020-05-11",
  path: "/cronologia/semana-09#dia-11-may",
};
const Day59 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.880 nuevos positivos y 143 personas fallecidas.
          Permanecen hospitalizadas 122.730 personas, 465 más que el día
          anterior, y 2.214 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.328, lo que supone un aumento de 36 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,1 % y la tasa de recuperados del 51,4 %.
        </ModText>
        <ModText>
          El <strong>Fondo COVID</strong> que gestiona el Instituto de Salud
          Carlos III (ISCIII), organismo adscrito al Ministerio de Ciencia e
          Innovación, ha
          <strong>aprobado 37 nuevos proyectos de investigación</strong> que
          mejoren a corto plazo el manejo del coronavirus. Estos proyectos,
          aprobados a lo largo de la semana pasada, suponen una inversión de
          casi 3,5 millones.
        </ModText>
        <ModText>
          La empresa sevillana <strong>Plus Vitech</strong> está trabajando en
          un “potencial tratamiento altamente efectivo” denominado
          <strong>PVT-COVID</strong>, que ha sido uno de los proyectos ganadores
          del hackathon paneuropeo #EUvsVirus en el área de salud, una
          iniciativa de la Comisión Europea en colaboración con los estados
          miembros de la UE.
        </ModText>
        <ModImage
          src="/images/svg/22_abr_investigacion-genetica.svg"
          alt="trabajando en un tratamiento contra el SARS-CoV-2"
        />

        <ModText>
          En terreno socioeconómico, hoy se ha firmado un
          <strong>Acuerdo Social</strong> para que aquellas empresas que no
          puedan reiniciar su actividad, por causas de fuerza mayor, puedan
          <strong>prorrogar los ERTE hasta el próximo 30 de junio</strong>.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day59;
